@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;600;700&display=swap');
/* @import '~antd/dist/antd.css'; */

:root:root {
  --my-dark-color: #002d4c;
  --my-bright-color: #79c6ed;

  --adm-color-primary: var(--my-bright-color);
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-color-text: var(--my-dark-color);

  --adm-font-family: 'Space Grotesk', -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, segoe ui, arial, roboto, 'PingFang SC',
    'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}

body {
  margin: 0px;
  min-height: 100vh;
  background-color: var(--my-dark-color);
}
/*
h1 {
  font-size: 14px;
}

.fixed-top {
  z-index: 10 !important;
}

nav > div {
  padding: 7px;
}

.navbar {
  padding: 2px !important;
  height: 60px;
  font-size: 1.3em;
}

.under-navbar {
  margin-top: 60px;
}

.add-intervention-btn {
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.new-intervention-panel {
  background: #fff;  
  font-size: 1em;
}

.new-intervention-panel .form-row {
}

.new-intervention-panel__block {
  background: #ffffff;
  margin: 5px 0;
  padding: 6px;
}

.new-intervention-panel__block > h4 {
  font-size: 1.1em;
  font-variant: small-caps;
  color: #444444;
}
.new-intervention-panel__block > .prestations {
  text-align: center;
}
.new-intervention-panel__block > .prestations > button {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin: 10px;
}

.new-intervention-panel__popin {
  background: #fff;
}

.new-intervention-panel__popin__service {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.new-intervention-panel__popin__service .row {
  width: 100%;
  margin: 0px;
  background: #fefeff;
}
.new-intervention-panel__popin__service h3 {
  font-size: 18px;
  text-align: center;
  padding: 10px 20px;
  font-weight: bold;
  width: 100%;
  background: #f8f8f8;
}
.new-intervention-panel__popin__service__save {
  text-align: center;
  font-variant-caps: all-petite-caps;
  font-size: 20px;
  width: 50%;
  border: 0px;
  height: 40px;
  background: #28a745;
  color: white;
  overflow: hidden;
}

.new-intervention-panel__popin__service__cancel {
  text-align: center;
  font-variant-caps: all-petite-caps;
  overflow: hidden;
  width: 50%;
  border: 0px;
  font-size: 20px;

  height: 40px;
  background: #dc3545;
  color: white;
}

.new-intervention-panel__save {
  position: fixed;
  left: 50%;
  right: 0px;
  bottom: 0px;
  height: 65px;
  background: #fff;
  text-align: center;
  padding: 12px;
  color: #28a745;
  font-weight: normal;
  letter-spacing: 2.5px;
}

.new-intervention-panel__cancel {
  position: fixed;
  width: 50%;
  bottom: 0px;
  height: 65px;
  background: #fff;
  text-align: center;
  padding: 12px;
  color: #dc3545;
  font-weight: normal;
  letter-spacing: 2.5px;
}
.new-intervention-panel .form-control {
  margin-top: 15px;
  padding: 0px !important;
}

.modal {
  outline: none !important;
  background: white;
  font-size: 1.6rem;
  width: 76rem !important;
  max-width: 90%;
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15 !important;
  min-height: 47rem;

  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.modal-client {
  border-radius: 15px;
  padding: 20px;
  outline: none !important;
  bottom: 8px;
  position: fixed;
  background: white;
  font-size: 1.6rem;
  width: 95%;
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15 !important;
  min-height: 60%;

  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.modal-client__content {
  padding-top: 40px;
  width: 100%;
}
.modal-client__content__address {
  font-size: 0.9em;
}
.modal-client__content__name {
  font-size: 0.9em;
}
.modal-client__content__tel {
  font-size: 0.9em;
}

.Mui-selected {
  background-color: #3f51b5 !important;
}

#Layer_5 {
  width: 100%;
}
@keyframes hideshow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    transform: translateY(35px);
  }
}

.bubble1 {
  animation: hideshow 0.5s ease alternate infinite;
  animation-delay: 0.1s;
}

.bubble2 {
  animation: hideshow 0.5s ease alternate infinite;
  animation-delay: 0.3s;
}
.bubble3 {
  animation: hideshow 0.5s ease alternate infinite;
  animation-delay: 0.2s;
}
.bubble4 {
  animation: hideshow 0.5s ease alternate infinite;
  animation-delay: 0.4s;
}

.login-form {
  justify-content: center;
  min-height: 90vh;
}
.button-block {
  width: 100%;
}
.login-background {
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
} */

/* --- */
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--my-dark-color);
}

.adm-nav-bar {
  color: var(--my-bright-color);
}

.adm-index-bar {
  background-color: var(--my-dark-color) !important;
}
.adm-index-bar-anchor-title {
  background-color: var(--my-dark-color) !important;
}

.dark-background {
  background: var(--my-dark-color) !important;
}

.top {
  flex: 0;
  /* border-bottom: solid 1px var(--adm-border-color); */
  position: sticky;
  top: 0;
  /* background: cornflowerblue; */
  z-index: 1000;
}

.body {
  flex: 0 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-direction: column;
}

.bottom {
  flex: 0;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 990;
  background: #003958;
}

.interventions-list > .adm-index-bar-sidebar {
  display: none;
}

.plates-image-preview {
  position: relative;
  text-align: center;
}

.plates-image-preview > img {
  width: 768px;
}

@media (max-width: 768px) {
  .plates-image-preview > img {
    width: 100%;
  }
}

/* fullscreen enable by props */
.plates-image-preview-fullscreen > img {
  width: 100vw;
  height: 100vh;
}

.screenshot-btn > .adm-floating-bubble-button {
  margin-right: -40px;
}

.adm-picker-view {
  flex-direction: row-reverse;
}
